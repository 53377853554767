const flatsContentInit = () => {
    const $window = $(window);
    const $flats_table_row = $('.js-flats-tr');
    const $table_wrapper = $('.js-table-wrapper');
    const $flat_table_tooltip = $(".js-flats-tooltip");

    let allow_an_impression = 1,
            thisJSON,
            panorama_show = false;

    $flats_table_row.hover(function (e) {
        if (allow_an_impression === 1) {
            let table_wrapper_left = $table_wrapper.offset().left - $flat_table_tooltip.width(),
                    thisPreview = $(this).attr('data-preview');

            $flat_table_tooltip.stop(true, true).css({opacity: "1", display: "block", zIndex: "1000"}).css({
                "top": e.pageY - 115,
                "left": table_wrapper_left
            });
            $flat_table_tooltip.find('.flats__table-help-inner').css({"background-image": `url("${thisPreview}")`, "filter": "invert(33%) sepia(50%) saturate(880%) hue-rotate(180deg)"});
        }
    }, function () {
        if (allow_an_impression === 1) {
            $flat_table_tooltip.stop(true, true).css({opacity: "0", display: "none"})
        }
    });

    $flats_table_row.mousemove(function (e) {
        if (allow_an_impression === 1) {
            $flat_table_tooltip.css({top: e.pageY - 115});
        }
    });

    $window.on('load resize', function () {
        let this_width = $(this).width();
        if (this_width <= 1023) {
            allow_an_impression = 0;
        } else {
            allow_an_impression = 1;
        }
    });

    //rangers
    let $filter_price_ranger = $("#flats_price_slider"),
            $filter_area_ranger = $("#flats_area_slider");

    //показ квартиры
    $flats_table_row.on('click', function () {
        let $thisSection = $('.js-flats-section'),
                $thisRoomsWord = $('.js-flats-rooms-word'),
                $thisGuid = $('#buy-online'),
                $thisNumber = $('.js-flats-number'),
                $thisPreview = $('.js-flats-preview'),
                $thisFloorPreview = $('.js-floor-preview'),
                $thisFloor = $('.js-flats-floor'),
                $thisRooms = $('.js-flats-rooms'),
                $thisArea = $('.js-flats-area'),
                $thisPrice = $('.js-flats-price'),
                $thisPriceMin = $('.js-flats-price-min'),
                $thisMeter = $('.js-flats-meter'),
                $thisMeterMin = $('.js-flats-meter-min'),
                // $thisDownloadLink = $('.js-modal-download'),
                $modal_price_line = $('.js_modal_price_line'),
                $js_modal_price_min = $('.js_modal_price_min'),
                // $thisDownloadLink_href = $thisDownloadLink.attr('href').split('/'),
                $thisHousing = $('.js-flats-housing'),
                $flatAdditionalOne = $('.js-flat-additional-option-one'),
                $flatAdditionalTwo = $('.js-flat-additional-option-two'),
                $flatAdditionalThree = $('.js-flat-additional-option-three'),
                $flatAdditionalInputs = $('.js-flat-additional-input'),
                this_download_link;

        thisJSON = $(this).data('content');
        this_download_link = `pdf/flat/${thisJSON.printFlatId}`;

        if ((thisJSON.min_price !== undefined) && (thisJSON.min_price !== null) && (Number(thisJSON.min_price) !== 0)) {
            $modal_price_line.addClass('actions');
            $modal_price_line.addClass('show__prices_line-through');
            $thisPriceMin.html(thisJSON.min_price);
            $thisMeterMin.html(thisJSON.min_meter);
            $js_modal_price_min.removeClass('hidden');
        } else {
            $modal_price_line.removeClass('actions');
            $modal_price_line.removeClass('show__prices_line-through');
            $thisPriceMin.html('');
            $thisMeterMin.html('');
            $js_modal_price_min.addClass('hidden');
        }

        switch (thisJSON.roomsNumber) {
            case 1:
                $thisRoomsWord.html('1-комнатная');
                break;
            case 2:
                $thisRoomsWord.html('2-комнатная');
                break;
            case 3:
                $thisRoomsWord.html('3-комнатная');
                break;
            case 4:
                $thisRoomsWord.html('4-комнатная');
                break;
        }

        $thisGuid.attr('data-value', thisJSON.guid);
        $thisSection.html(thisJSON.section);
        $thisNumber.html(thisJSON.conditionalNumber);
        $thisFloor.html(`${thisJSON.floor} / ${thisJSON.maxFloor}`);
        $thisRooms.html(thisJSON.roomsNumber);
        $thisArea.html(thisJSON.area);
        $thisPrice.html(thisJSON.price);
        $thisMeter.html(thisJSON.meter);
        $thisHousing.html(thisJSON.housingNumber);

        $flatAdditionalTwo.removeClass('hidden');
        $flatAdditionalThree.removeClass('hidden');

        $flatAdditionalOne.attr('data-price', thisJSON.price);
        if (thisJSON.prices.cost_with_finishing.total === 0) {
            $flatAdditionalTwo.addClass('hidden');
        }  else {
            $flatAdditionalTwo.attr('data-price', $(this).find('.js-option-two').html())
        }
        if (thisJSON.prices.cost_with_tech.total === 0) {
            $flatAdditionalThree.addClass('hidden');
        }  else {
            $flatAdditionalThree.attr('data-price', $(this).find('.js-option-three').html())
        }

        if (thisJSON.previewLink !== '/img/no-image.svg' && thisJSON.floorLink) {
            $('.tabs__selector-text').css({'display': 'inline-block'});
            $('.tabs__btn').css({'display': 'inline-block'});
        }

        $thisPreview.attr('src', thisJSON.previewLink);
        $thisFloorPreview.attr('src', thisJSON.floorLink);

        $flatAdditionalInputs.click(function () {
            $thisPrice.html($(this).data('price'));
        });

        $('.js-price-additional-input').each(function () {
            if ($(this).is(':checked')) {
                if (!$(`.js-flat-additional-${$(this).val()}`).hasClass('hidden')) {
                    $(`.js-flat-additional-${$(this).val()}`).click();
                } else {
                    $flatAdditionalOne.click();
                }
            }
        });

        /*$thisDownloadLink.each(function () {
            $(this).attr('href', this_download_link);
        });*/
    });

    let $panorama_tab = $('.js-panorama-tab');

    //panorama
    let render_config = {
        'position_x': 300,
        'position_y': 0,
        'panorama': 'img/panorama/panorama.jpg',
        'container_id': 'flats_panorama',
        'turning': false,
        'arrows_show': true
    };

    $panorama_tab.on('click', function () {
        panorama_show = true;

        let this_floor = thisJSON.floor,
                this_panorams = [
                    'img/panorama/panorama_30.jpg',
                    'img/panorama/panorama_50.jpg',
                    'img/panorama/panorama_70.jpg',
                    'img/panorama/panorama_90.jpg',
                    'img/panorama/panorama_110.jpg'
                ];

        switch (true) {
            case (this_floor >= 1 && this_floor <= 5): {
                render_config.position_x = 300;
                render_config.position_y = 0;
                render_config.panorama = this_panorams[0];
                break;
            }
            case (this_floor >= 6 && this_floor <= 10): {
                render_config.position_x = 310;
                render_config.position_y = -10;
                render_config.panorama = this_panorams[1];
                break;
            }
            case (this_floor >= 11 && this_floor <= 15): {
                render_config.position_x = 170;
                render_config.position_y = -20;
                render_config.panorama = this_panorams[2];
                break;
            }
            case (this_floor >= 16 && this_floor <= 20): {
                render_config.position_x = 250;
                render_config.position_y = -30;
                render_config.panorama = this_panorams[3];
                break;
            }
            case (this_floor >= 21): {
                render_config.position_x = 180;
                render_config.position_y = -25;
                render_config.panorama = this_panorams[4];
                break;
            }
        }

        if ($('#flats_panorama').is(':empty')) {
            starting_render(render_config);
        }
    });

    $flats_table_row.fancybox({
        afterClose: function (instance, current) {
            $('#flats_panorama').empty();
        },
        afterShow: function (instance, current) {
            if (panorama_show) {
                $panorama_tab.trigger('click');
            }
        }
    });

    let $tabsBtn = $('.js-tabs-btn:not(.js-panorama-tab)');

    $tabsBtn.on('click', function () {
        panorama_show = false;
    });

    let $flats_table = $('.js-table-sort'),
            $all_flats_metrics = $('.js-flats-metrics'),
            all_flats_metric = JSON.parse($all_flats_metrics.attr('data-flats'));

    //$flats_table.tablesorter();

    let filter_flats = {
        'section': '0',
        'floor': {
            'min': all_flats_metric.floor.from,
            'max': all_flats_metric.floor.to,
        },
        'flats': [],
        'price': {
            'min': Math.round(all_flats_metric.price.from),
            'max': Math.round(all_flats_metric.price.to)
        },
        'square': {
            'min': all_flats_metric.square.from,
            'max': all_flats_metric.square.to
        },
        'additions': 'all',
    };

    const flatPriceMin = document.getElementById('flats_price_input_from');
    const flatPriceMax = document.getElementById('flats_price_input_to');
    const flatAreaMin = document.getElementById('flats_area_input_from');
    const flatAreaMax = document.getElementById('flats_area_input_to');
    const inputsArray = [flatPriceMin, flatPriceMax, flatAreaMin, flatAreaMax];

    const setValueInRanks = (element, rank, min, maxRank) => {
        if (min && Number(element.value.replace(/\s/g, '')) < Math.round(rank)) {
            element.value = `${Math.round(rank).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
        } else if (min && Number(element.value.replace(/\s/g, '')) > Math.round(maxRank)) {
            element.value = `${Math.round(maxRank).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
        } else if (min) {
            return false;
        }

        if (Number(element.value.replace(/\s/g, '')) > Math.round(rank)) {
            element.value = `${Math.round(rank).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}`;
        }
    }

    inputsArray.forEach(input => {
        input.addEventListener('blur', () => {
            switch (input.getAttribute('id')) {
                case 'flats_price_input_from':
                    setValueInRanks(input, all_flats_metric.price.from, true, all_flats_metric.price.to);
                    break;
                case 'flats_price_input_to':
                    setValueInRanks(input, all_flats_metric.price.to);
                    break;
                case 'flats_area_input_from':
                    setValueInRanks(input, all_flats_metric.square.from, true, all_flats_metric.square.to);
                    break;
                case 'flats_area_input_to':
                    setValueInRanks(input, all_flats_metric.square.to);
                    break;
            }
        });
    });

    // const switchTabToBegin = () => {
    //     const flatRow = Array.from(document.querySelectorAll('.js-flats-tr'));
    //
    //     const switchTab = () => {
    //         const firstTab = document.querySelector('.flats__tabs .js-tabs-btn');
    //
    //         return firstTab.click()
    //     }
    //
    //     flatRow.forEach(row => {
    //         row.addEventListener('click', switchTab);
    //     });
    // }
    //
    // switchTabToBegin();

    //фильтрация квартир
    //контрол секции
    let $filterSection = $('.js-filter-section'),
            $filterFloorMin = $('.js-filter-floor-min'),
            $filterFloorMax = $('.js-filter-floor-max'),
            $filterRooms = $('.js-filter-rooms'),
            $this_tr = $flats_table.find('.js-flats-tr'),
            $about_romms_link = $('.js-about-rooms'),
            $filter_clear = $('.js-filter-clear'),
            $filter_start = $('.js-filter-start'),
            $flats_count_block = $('.js-flats-counter'),
            $searth_no_result = $('.js-search-result'),
            $table_inner_block = $('.js-table-inner'),
            $js_sticker_floors = $('.js-sticker-floors'),
            $js_additions_input = $('.js-additions-input'),
            flats_count = 0;

    let flatsTableFilter = function () {
        $this_tr.each(function () {

            let this_section_val = $(this).find('.js-td-section').html(),
                    this_floor_val = $(this).find('.js-td-floor').html(),
                    this_rooms_val = $(this).find('.js-td-rooms').html(),
                    this_price_val = $(this).find('.js-td-price').html(),
                    this_area_val = $(this).find('.js-td-area').html(),
                    this_additions_val = $.trim($(this).find('.js-td-additional').html());

            let filter_flats_max = (Number(this_floor_val) <= Number(filter_flats.floor.max)),
                    filter_flats_min = (Number(this_floor_val) >= Number(filter_flats.floor.min)),
                    filter_price_max = (Number(this_price_val) <= Number(filter_flats.price.max)),
                    filter_price_min = (Number(this_price_val) >= (Number(filter_flats.price.min)) - 1000),
                    filter_area_max = (Number(this_area_val) <= Number(filter_flats.square.max)),
                    filter_area_min = (Number(this_area_val) >= Number(filter_flats.square.min)),
                    filter_additions = true;

            if (filter_flats.additions !== 'all') {
                filter_additions = this_additions_val === filter_flats.additions;
            }

            if (filter_flats.section === '0') {
                this_section_val = '0'
            }

            if (filter_flats.flats.length !== 0) {
                let flat_rooms_show = $.inArray(this_rooms_val, filter_flats.flats);

                if ((this_section_val === filter_flats.section) && (filter_flats_max && filter_flats_min) && (filter_price_max && filter_price_min) && (filter_area_max && filter_area_min) && filter_additions && (flat_rooms_show !== -1)) {
                    $(this).css('display', '');
                } else {
                    $(this).css('display', 'none');
                }
            } else {
                if ((this_section_val === filter_flats.section) && (filter_flats_max && filter_flats_min) && (filter_price_max && filter_price_min) && (filter_area_max && filter_area_min) && filter_additions) {
                    $(this).css('display', '');
                } else {
                    $(this).css('display', 'none');
                }
            }
        });
        flatsCounters();
    };

    let flatFilterClear = function () {
        let range_price = $filter_price_ranger.data("ionRangeSlider"),
                range_area = $filter_area_ranger.data("ionRangeSlider");

        $filterRooms.each(function () {
            $(this).prop('checked', false);
        });

        $filterSection.prop('selectedIndex', 0);
        $filterFloorMin.prop('selectedIndex', 0);
        $filterFloorMax.prop('selectedIndex', 0);

        range_price.update({
            min: Math.round(all_flats_metric.price.from),
            max: Math.round(all_flats_metric.price.to),
            from: Math.round(all_flats_metric.price.from),
            to: Math.round(all_flats_metric.price.to)
        });

        range_area.update({
            min: Math.round(all_flats_metric.square.from),
            max: Math.round(all_flats_metric.square.to),
            from: Math.round(all_flats_metric.square.from),
            to: Math.round(all_flats_metric.square.to)
        });

        filter_flats.section = '0';
        filter_flats.floor.min = '0';
        filter_flats.floor.max = '32';
        filter_flats.flats = [];
        filter_flats.price.min = Math.round(all_flats_metric.price.from);
        filter_flats.price.max = Math.round(all_flats_metric.price.to);
        filter_flats.square.min = all_flats_metric.square.from;
        filter_flats.square.max = all_flats_metric.square.to;
        filter_flats.additions = 'all';
        flatsTableFilter();
    };

    let flatsCounters = function () {
        let $this_tr = $flats_table.find('.js-flats-tr');
        flats_count = 0;
        $this_tr.each(function () {
            if ($(this).css('display') !== 'none') {
                flats_count++;
            }
        });
        $flats_count_block.html(flats_count);
        if (flats_count === 0) {
            $searth_no_result.css({'display': 'block'});
            $table_inner_block.css({'display': 'none'});
            $table_wrapper.addClass('no-result');
        } else {
            $searth_no_result.css({'display': 'none'});
            $table_inner_block.css({'display': 'block'});
            $table_wrapper.removeClass('no-result');
        }
    };

    $filterSection.on('change', function () {
        filter_flats.section = $(this).val();
        // flatsTableFilter();
    });

    $filterFloorMin.on('change', function () {
        if (Number($(this).val()) > Number($filterFloorMax.val())) {
            $(this).val($filterFloorMax.val());
        }
        filter_flats.floor.min = $(this).val();
        // flatsTableFilter();
    });

    $filterFloorMax.on('change', function () {
        if (Number($(this).val()) < Number($filterFloorMin.val())) {
            $(this).val($filterFloorMin.val());
        }
        filter_flats.floor.max = $(this).val();
        // flatsTableFilter();
    });

    $filterRooms.on('change', function () {
        filter_flats.flats.splice(0, filter_flats.flats.length);
        $filterRooms.each(function (index) {
            if ($($filterRooms[index]).is(':checked')) {
                let this_val = $($filterRooms[index]).attr('data-value');
                filter_flats.flats.push(this_val);
            }
        });
        // flatsTableFilter();
    });

    $about_romms_link.on('click', function () {
        let $this = $(this),
                this_rooms = $this.attr('data-rooms');
        $('#flat_' + this_rooms).prop('checked', true);
        $filterRooms.trigger('change');
        flatsTableFilter();
    });

    $js_sticker_floors.on('click', function () {
        let $this = $(this),
                this_min = Number($this.data('floor-min')),
                this_max = Number($this.data('floor-max'));
        $filterFloorMin.prop('selectedIndex', 9);
        $filterFloorMin.trigger('change');
        filter_flats.floor.max = $this.data('floor-max');
        filter_flats.floor.min = $this.data('floor-min');
        flatsTableFilter();
    });

    function sectionChanged(section_number) {
        $filterSection.prop('selectedIndex', section_number);
        $filterSection.trigger('change');
        $genplane_path.removeClass('hover');
        $genplane_mark.removeClass('hover');
        flatsTableFilter();
        $.fancybox.close();
    }

    $filter_price_ranger.on('change', function () {
        let $this = $(this),
                from = $this.data("from"),
                to = $this.data("to");
        filter_flats.price.min = from;
        filter_flats.price.max = to;
        // flatsTableFilter();
    });

    $filter_area_ranger.on('change', function () {
        let $this = $(this),
                from = $this.data("from"),
                to = $this.data("to");
        filter_flats.square.min = from;
        filter_flats.square.max = to;
        // flatsTableFilter();
    });

    let
        btnWithFinishing = false,
        btnWithoutFinishing = false;

    $js_additions_input.on('click', function() {
        const $this = $(this);

        $this[0].value === 'with-finishing' ? btnWithFinishing = !btnWithFinishing : btnWithoutFinishing = !btnWithoutFinishing;

        if (btnWithFinishing && btnWithoutFinishing) {
            filter_flats.additions = 'all';
        } else if (btnWithFinishing) {
            filter_flats.additions = 'Да';
        } else if (btnWithoutFinishing) {
            filter_flats.additions = 'Нет';
        } else {
            filter_flats.additions = 'all';
        }
    });

    let $double_range = $('.js-filter-group-range');

    $double_range.each(function () {
        let $thisInputs = $(this).find('.input-text');

        $(this).rangerInputs('init');

        $thisInputs.each(function () {
            let inputs_formated = new Cleave($(this), {
                delimiter: ' ',
                numeral: true,
                numeralThousandsGroupStyle: 'thousand'
            });
        });
    });


    $filter_clear.on('click', function () {
        flatFilterClear();
    });

    $filter_start.on('click', function () {
        flatsTableFilter();
    });

    flatFilterClear();

    let $genplane_path = $('#flatsGenplane path'),
            $genplane_mark = $('.js-plane-mark');

    $genplane_path.click(e => {
        let thisSection = $(e.currentTarget).data('section');
        sectionChanged(thisSection)
    });
    $genplane_mark.click(e => {
        let thisSection = $(e.currentTarget).data('section');
        sectionChanged(thisSection)
    });

    $genplane_mark.hover(e => {
        let $it_this = $(e.currentTarget),
                thisSection = $it_this.data('section');

        $it_this.addClass('hover');
        $(`#section${thisSection}`).addClass('hover');
    }, e => {
        let $it_this = $(e.currentTarget),
                thisSection = $it_this.data('section');

        $it_this.removeClass('hover');
        $(`#section${thisSection}`).removeClass('hover');
    });

    $genplane_path.hover(e => {
        let $it_this = $(e.currentTarget),
                thisSection = $it_this.data('section');

        $it_this.addClass('hover');
        $(`#mark${thisSection}`).addClass('hover');
    }, e => {
        let $it_this = $(e.currentTarget),
                thisSection = $it_this.data('section');

        $it_this.removeClass('hover');
        $(`#mark${thisSection}`).removeClass('hover');
    });
};

const isSpecificNumber = (number) => {
    return (number !== undefined) && (number !== null) && (Number(number) !== 0);
};

const tableSort = () => {
    // функция получения данных из ячейки в строке по индексу
    const getCellValue = (tr, idx) => {
        let value = $(tr.children[idx]).attr('data-sortData');

        if (value === undefined) {
            value = tr.children[idx].innerText || tr.children[idx].textContent;
        }

        return value;
    };

    // функция сравнения
    const comparer = (idx, asc) => (a, b) => ((v1, v2) => {
            [v1, v2] = [v1.replace(/\s/g, ''), v2.replace(/\s/g, '')];
            return v1 !== '' && v2 !== '' && isNaN(v1) && isNaN(v2)
                ? parseFloat(v1) - parseFloat(v2)
                : v1.toString().localeCompare(v2)
        }
    )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx));

    // строки таблицы с данными по объектам
    const table = document.querySelector('.js_flatsResponse');

    // строки табличной шапки, их 2 ибо она статическая, а 2я фиксированная
    const tableHeads = Array.from(document.querySelectorAll('.js-table-sort thead tr'));

    // обработчик клика сортировки
    const sortClick = () => {
        const sortButtons = Array.from(document.querySelectorAll('.table__sorted'));
        let activeColumn;

        sortButtons.forEach(button => {
            button.addEventListener('click', (e) => {
                e.preventDefault();
                activeColumn = button.closest('th').cellIndex;

                tableHeads.map(head => {
                    const tableHeadCell = Array.from(head.querySelectorAll('th'))[activeColumn];
                    const activeButtons = Array.from(tableHeadCell.querySelectorAll('.table__sorted'));

                    activeButtons.map(activeButton => {
                        sortButtons.map(button => {
                            if (button.closest('th').cellIndex !== activeColumn) {
                                button.classList.remove('table--up');
                            }
                        });

                        activeButton.classList.toggle('table--up');
                    });
                });

                Array.from(table.querySelectorAll('tr'))
                  .sort(comparer(activeColumn, this.asc = !this.asc))
                  .forEach(tr => table.appendChild(tr) );
            })
        })
    };

    sortClick();
}

// функция рендера таблицы
const tableRender = () => {
    const $flatsResponse = $('.js_flatsResponse');
    const $flatsLoading = $('.js_flatsLoading');
    let trTemplate,
        flatMetrics,
        minMeter,
        minPrice,
        previewImage,
        tablePreview,
        floorImage;

    fetch('/data')
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                let flatsJSON = data.flats;

                for (const item in flatsJSON) {

                    minPrice = isSpecificNumber(flatsJSON[item].minPrice) ? (Math.trunc(flatsJSON[item].minPrice)).toLocaleString('ru-RU') : 0;
                    minMeter = isSpecificNumber(flatsJSON[item].minMeterPrice) ? (Math.trunc(flatsJSON[item].minMeterPrice)).toLocaleString('ru-RU') : 0;

                    previewImage = flatsJSON[item].view2DBigUrl !== null ? flatsJSON[item].view2DBigUrl : '/img/no-image.svg';
                    floorImage = flatsJSON[item].layoutBigUrl !== null ? flatsJSON[item].layoutBigUrl : '/img/no-image.svg';

                    if (flatsJSON[item].view2DSmallUrl !== null) {
                        tablePreview = flatsJSON[item].view2DSmallUrl;
                    } else {
                        tablePreview = flatsJSON[item].layoutSmallUrl !== null ? flatsJSON[item].layoutSmallUrl : '/img/no-image.svg';
                    }

                    flatMetrics = JSON.stringify({
                        guid: flatsJSON[item].guid,
                        flat_id: item,
                        section: flatsJSON[item].section,
                        floor: flatsJSON[item].floor,
                        conditionalNumber: flatsJSON[item].conditionalNumber,
                        roomsNumber: flatsJSON[item].roomsNumber,
                        area: flatsJSON[item].area,
                        price: (Math.trunc(flatsJSON[item].price)).toLocaleString('ru-RU'),
                        meter: (Math.trunc(flatsJSON[item].meterPrice)).toLocaleString('ru-RU'),
                        min_price: minPrice,
                        min_meter: minMeter,
                        previewLink: previewImage,
                        floorLink: floorImage,
                        printFlatId: flatsJSON[item].id,
                        housingNumber: flatsJSON[item].housingNumber
                    });

                    trTemplate +=
                        `
                        <tr class="table__body-tr js-flats-tr" data-preview="${tablePreview}" data-smallBtn="false" data-fancybox data-src="#flatsContent" data-content='${flatMetrics}' data-touch="false">
                            <td class="table__td js-td-section">${flatsJSON[item].housingNumber}</td>
                            <td class="table__td table--hidden js-td-floor">${flatsJSON[item].floor }</td>
                            <td class="table__td">${flatsJSON[item].conditionalNumber}</td>
                            <td class="table__td table--hidden js-td-rooms">${flatsJSON[item].roomsNumber}</td>
                            <td class="table__td table--hidden js-td-area">${flatsJSON[item].area}</td>
                            <td class="table__td" data-sortData="${flatsJSON[item].price}">
                                <div class="table__price">
                                    <div class="table__price-normal">${(Math.trunc(flatsJSON[item].price)).toLocaleString('ru-RU')}</div>
                                    <div class="table__price-meter">${(Math.trunc(flatsJSON[item].meterPrice)).toLocaleString('ru-RU')} / м²</div>
                                    <div class="hidden js-td-price">${Math.trunc(flatsJSON[item].price)}</div>
                                </div>
                            </td>
                        </tr>
                        `;
                }

                $flatsResponse.html(trTemplate);
                $flatsLoading.removeClass('flats-loading');

                flatsContentInit();

            });
};

// tableRender();
tableSort();
flatsContentInit();

// Пеереключеие дополительой цены
const switchAdditionalPrice = () => {
    const $table = $('.js-table-flats');
    const $radioInput = $('.js-price-additional-input');

    $radioInput.click(function () {
        const $this = $(this);
        $table
            .removeClass('option-one')
            .removeClass('option-two')
            .removeClass('option-three')
            .addClass($this.val());
    });
}
switchAdditionalPrice();

/*const mobileFilterToggle = () => {
    const filterToggleButton = document.querySelector('.js_filterToggleButton');
    const filterToggleWrapper = document.querySelector('.js_filterToggleWrapper');

    filterToggleButton.addEventListener('click', () => {
        const itIsActive = filterToggleButton.classList.contains('active');
        filterToggleButton.classList.toggle('active');
        filterToggleButton.textContent = itIsActive ? 'Скрыть фильтр' : 'Фильтр';
        filterToggleWrapper.classList.toggle('flats__toggle-hidden');
    });
};*/

$(document).ready(function () {
    const $window = $(window);
    const $flatsWrapper = $('#flats');

    if ($flatsWrapper.length > 0) {
        $window.on('load scroll', () => {
            let $flatsWrapperBlockPosition = $flatsWrapper.offset().top - 200,
              thisWindowScroll = $(window).scrollTop();

            if (thisWindowScroll > $flatsWrapperBlockPosition && !$flatsWrapper.hasClass('flats-load')) {
                $flatsWrapper.addClass('flats-load');

                // отрисовка таблицы
            }
        });
    }

    // для фильтра на мобильной версии
    // mobileFilterToggle();

    //tabs
    let $tabsBtn = $('.js-tabs-btn'),
        $tabsSelect = $('.js-tabs-select');

    $tabsBtn.on('click', function () {
        let thisId = $(this).attr('data-tabs'),
            thisActive = $(this).hasClass('tabs__btn--active'),
            $thisParent = $(this).parents('.js-tabs-buttons'),
            $thisContent = $thisParent.next('.js-tabs-content');

        if (thisActive !== true) {
            $thisParent.find('.js-tabs-btn').removeClass('tabs__btn--active');
            $(this).addClass('tabs__btn--active');
            $thisContent.find('.tabs__content-tab').removeClass('tabs__content--active');
            $thisContent.find('.tabs__content-' + thisId).addClass('tabs__content--active');
            $thisParent.removeClass('tabs__buttons--active');
            $thisParent.find('.tabs__selector-text').removeClass('tabs__selector--active');
            $thisParent.find('.tabs__selector-' + thisId).addClass('tabs__selector--active');
        }
    });

    $tabsSelect.on('click', function () {
        let $thisParent = $(this).parents('.js-tabs-buttons'),
            thisActive = $thisParent.hasClass('tabs__buttons--active');

        if (thisActive !== true) {
            $thisParent.addClass('tabs__buttons--active');
        } else {
            $thisParent.removeClass('tabs__buttons--active');
        }
    });

    // маска на телефоны
    let $inputTypeTel = $('.js-sendform--clientTel');
    $inputTypeTel.mask("+7 (999) 999-99-99");

    $('[data-src="#requestСall"]').on('click', () => {
        $(this).removeClass('request-call--thanks');
    });

    // отправка формы обратного звонка
    $('.js-sendform--submit-flat').click(function (event) {
        event.preventDefault();
        checkSubmitForm($(event.currentTarget), 'Обратный звонок');
    });

    function checkSubmitForm($this, messageText) {
        let $thisFormLayer = $this.parents('.js-sendform--wrapper'), // оборачиваем все что касается формы
            $thisForm = $thisFormLayer.find('.js-sendform--section'), // начальный блок (с полями, заголовками)
            $thisSuccess = $thisFormLayer.find('.js-sendform--success'), // блок после успешной отправки
            $thisErrorMessage = $thisFormLayer.find('.js-sendform--error_message'), // поле для вывода сообщения если форма не отправилась
            $thisName = $thisFormLayer.find('.js-sendform--clientName').val(),
            $thisNameInput = $thisFormLayer.find('.js-sendform--clientName'),
            $thisPhone = $thisFormLayer.find('.js-sendform--clientTel').val(),
            $thisPhoneInput = $thisFormLayer.find('.js-sendform--clientTel'),
            $thisNameBlock = $thisFormLayer.find('.js-sendform--clientName'),
            $thisPhoneBlock = $thisFormLayer.find('.js-sendform--clientTel'),
            $thisParentModal = $this.parents('.request-call-modal');

        function submitForm() {
            $.ajax({
                type: 'POST',
                url: "/send_order",
                headers: {
                    "Content-type": "application/x-www-form-urlencoded",
                    "X-Form-ID": "get_consult",
                },
                data: {firstname: $thisName, tel: $thisPhone, message: messageText, token: scripts.yaCaptchaToken},
                beforeSend: function () {
                    $this.attr('disabled', 'disabled');
                    $thisParentModal.addClass('request-call--thanks');
                    $thisForm.hide();
                    $thisSuccess.show();
                },
                success: function () {
                    $thisForm.hide();
                    $thisSuccess.show();
                    //dataLayer.push({'event': 'otpravka_form'});
                },
                error: function () {
                    $this.removeAttr('disabled');
                    $thisErrorMessage.text('Ошибка отправки. Попробуйте снова');
                }
            });
        }

        if (($thisNameInput.length) && $thisPhoneInput.length) {
            if (($thisName === '') && ($thisPhone === '')) {
                $thisNameBlock.addClass('input-text--error');
                $thisPhoneBlock.addClass('input-text--error');
            } else if ($thisName === '') {
                $thisPhoneBlock.removeClass('input-text--error');
                $thisNameBlock.addClass('input-text--error');
            } else if ($thisPhone === '') {
                $thisNameBlock.removeClass('input-text--error');
                $thisPhoneBlock.addClass('input-text--error');
            } else {
                submitForm();
            }
        } else if (!$thisNameInput.length) {
            if ($thisPhone === '') {
                $thisNameBlock.removeClass('input-text--error');
                $thisPhoneBlock.addClass('input-text--error');
            } else {
                submitForm();
            }
        }

        return false;
    }

});
