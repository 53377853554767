'use strict';

var $ = jQuery;
var scripts = {
    yandexCaptcha: {
        forms: {},
    },
};

// -------------------------------------------------------------
//   Basic Navigation
// -------------------------------------------------------------
$(document).ready(function () {
    if ($(window).width() < 415) {
        $('.sly_blok_wr .slides a').on('click', function (arguments) {
            $('.section3--block').addClass('no');
        });
        $('.card--back').on('click', function () {
            $('.section3--block').removeClass('no');
        });
    }

    var $frame = $('#basic');
    var $slidee = $frame.children('ul').eq(0);
    var $wrap = $frame.parent();

    $frame.sly({
        horizontal: 1,
        itemNav: 'basic',
        smart: 1,
        // activateOn: 'click',
        mouseDragging: 1,
        touchDragging: 1,
        releaseSwing: 1,
        startAt: 0,
        scrollBar: $wrap.find('.scrollbar'),
        scrollBy: 1,
        speed: 300,
        elasticBounds: 1,
        dragHandle: 1,
        dynamicHandle: 1,
        clickBar: 1,

        minHandleSize: 10,

        prev: $wrap.find('.prev_sly'),
        next: $wrap.find('.next_sly')

    });

    var sly_sl = $('.sly_blok .slides').length;
    $('.count_slides_num').html(sly_sl);
    var currentIndex = $('.sly_blok .slides.active').index() + 1;
    $('.current_slider').html(currentIndex);

    // Change count slider
    function updateCountSlide() {
        var currentIndex = $('.sly_blok .slides.active').index() + 1;
        $('.current_slider').html(currentIndex);
    }

    $('.prev_sly , .next_sly').on('click', updateCountSlide);


    if ($(window).width() < 415) {

        $('.section-1').attr('id', 'section-1');
        $('.section-2').attr('id', 'section-2');
        $('.section-3').attr('id', 'section-3');
        $('.section-4').attr('id', 'section-4');
        $('.section-5').attr('id', 'section-5');
        $('.section-6').attr('id', 'section-6');
        $('.section-7').attr('id', 'section-7');
        $('.section-8').attr('id', 'section-8');

        $('.fixmenu--nav a').click(function () {
            var el = $(this).attr('href');
            var elWrapped = $(el);
            scrollToDiv(elWrapped, 60);
            return false;
        });

        $('a.object_thumb').on('click', function () {
            $('.sly_block_wrapp').addClass('height');
            // $('.sly_block_wrapp').removeClass('height');
        });

        function scrollToDiv(element, navheight) {
            var offset = element.offset();
            var offsetTop = offset.top;
            var totalScroll = offsetTop - navheight;
            $('body,html').animate({
                scrollTop: totalScroll
            }, 1000);
        }

        $('.fixmenu a').on('click', function () {
            $('.fixmenu').removeClass('open');
        });

        $('.icon-roundWrappLink').click(function () {
            var href = $(this).attr('href');
            $(this).parent().find('.icon-linkText').wrap('<a href="' + href + '"></a>');
            $(this).parent().addClass('active');
            return false;
        });

        $('.links-file-close').click(function () {
            $(this).parent().removeClass('active');
        });
    }
});

$(window).scroll(function () {
    var target = $('.section1').height() - 100;
    if ($(window).width() < 415) {
        var position = $(this).scrollTop();
        if (position > target) {
            $('.fixmenu').css('display', 'block');
        } else {
            $('.fixmenu').css('display', 'none');
        }
    }
});

// section1_img
$(document).ready(function () {
    $('.section1_img').slick({
        arrows: true,
        infinite: true,
        autoplaySpeed: 4000,
        dots: true,
        speed: 1200,
        // variableWidth: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: '.numbers_promo',
        appendArrows: '.count_promo_slides',
        prevArrow: '<button type="button" class="slide_btn prev_slide"><i class="icon-hover icon-arrovPrev"></i></button>',
        nextArrow: '<button type="button" class="slide_btn next_slide"><i class="icon-hover icon-arrovNext"></i></button>',
        customPaging: function (slider, i) {
            i = i + 1;
            return '<div class="current_slide_num">' + i + '</div><div class="count_slides">' + slider.slideCount + '</div>';
        },
    });

    var i = 0;
    $('.section1_text').each(function () {
        i++;
        $(this).attr('data-slick-index', i);
    });

    $('.section1_img').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var nextSlideNum = nextSlide + 1;
        $('.section1_text').removeClass('current');
        $('.section1_text[data-slick-index="' + nextSlideNum + '"]').addClass('current');
    });


    $('.image_popup, .cardImg a').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        }
    });

    /*if ($(window).width() > 415 && $(window).height() > 700) {
        $('#fullpage').fullpage({
            anchors: ['section-1', 'section-2', 'section-9', 'section-3', 'section-10', 'section-4', 'section-6', 'section-8'],
            showActiveTooltip: true,
            menu: '#menu',
            slidesNavPosition: 'top',
            verticalCentered: true,
            sectionSelector: '.section',
            onLeave: function (origin, destination, direction) {
                var leavingSection = this;

                // fixed_menu
                if (direction == 'down') {
                    $('.fixmenu').css('display', 'block');
                } else if (destination.index == 0) {
                    $('.fixmenu').css('display', 'none');
                }
            }
        });
    }*/

    let oldScrollPosition = window.pageYOffset;

    window.onscroll = function() {
        let currentScrollPosition = window.pageYOffset;

        if (currentScrollPosition > 200) {
            if (oldScrollPosition > currentScrollPosition) {
                $('.fixmenu').css('display', 'none');
            } else {
                $('.fixmenu').css('display', 'block');
            }
        }

        oldScrollPosition = currentScrollPosition;
    }

    // Popup формы.
    $('.popup_btn').magnificPopup({
        type: 'inline',
        preloader: false,
        callbacks: {
        open: function () {
            $('a[data-fancybox="images"]').fancybox();
        },
    }
    });

    const moo = $('.section5 .moo');
    document.getElementById('focus').addEventListener('click', function () {
        const L0 = moo[0].value.length;
        const L1 = moo[1].value.length;
        const L2 = moo[2].value.length;
        const L3 = moo[3].value.length;

        if (L0 == 3 && L1 == 3 && L2 == 2 && L3 == 2) {
            return;
        }
        if (L0 < 3) {
            moo[0].focus();
        } else if (L0 == 3 && L1 < 3) {
            moo[1].focus();
        } else if (L0 == 3 && L1 == 3 && L2 < 2) {
            moo[2].focus();
        } else if (L0 == 3 && L1 == 3 && L2 == 2) {
            moo[3].focus();
        }
    });

    for (let i = 0; i < moo.length; i++) {
        moo[i].addEventListener('input', function () {

            if (i == 0 && this.value.length == 3) {
                moo[(i + 1)].focus();
            } else if (i == 1 && this.value.length == 3) {
                moo[(i + 1)].focus();
            } else if (i == 2 && this.value.length == 2) {
                moo[(i + 1)].focus();
            } else if (i == 3 && this.value.length == 2) {
                moo[(i + 1)].focus();
            }
            if (moo[3].value.length > 2) {
                moo[3].value = moo[3].value.slice(0, 2);
            }
        });

        moo[i].addEventListener('keyup', function (e) {
            if (e.key.match(/Backspace/ig) && this.value.length == 0 && i > 0) {
                moo[(i - 1)].focus();
            }
        });
    }

    const moo1 = $('#popup_coll .moo');
    document.getElementById('focus').addEventListener('click', function () {
        const L0 = moo1[0].value.length;
        const L1 = moo1[1].value.length;
        const L2 = moo1[2].value.length;
        const L3 = moo1[3].value.length;
        if (L0 == 3 && L1 == 3 && L2 == 2 && L3 == 2) {
            return;
        }
        if (L0 < 3) {
            moo1[0].focus();
        } else if (L0 == 3 && L1 < 3) {
            moo1[1].focus();
        } else if (L0 == 3 && L1 == 3 && L2 < 2) {
            moo1[2].focus();
        } else if (L0 == 3 && L1 == 3 && L2 == 2) {
            moo1[3].focus();
        }
    });

    for (let i = 0; i < moo1.length; i++) {
        moo1[i].addEventListener('input', function () {

            if (i == 0 && this.value.length == 3) {
                moo1[(i + 1)].focus();
            } else if (i == 1 && this.value.length == 3) {
                moo1[(i + 1)].focus();
            } else if (i == 2 && this.value.length == 2) {
                moo1[(i + 1)].focus();
            } else if (i == 3 && this.value.length == 2) {
                moo1[(i + 1)].focus();
            }
            if (moo1[3].value.length > 2) {
                moo1[3].value = moo1[3].value.slice(0, 2);
            }
        });

        moo1[i].addEventListener('keyup', function (e) {
            if (e.key.match(/Backspace/ig) && this.value.length == 0 && i > 0) {
                moo1[(i - 1)].focus();
            }
        });
    }

    $('.questions_slider').slick({
        dots: false,
        arrows: true,
        autoplaySpeed: 4000,
        speed: 1200,
        slidesToShow: 3,
        slidesToScroll: 1,
        prevArrow: '<button class="icon-roundWrapp icon-parentHover slick-prev" aria-label="Previous" type="button"><i class="icon icon-arrovNextSm"></i></button>',
        nextArrow: '<button class="icon-roundWrapp icon-parentHover slick-next" aria-label="Next" type="button"><i class="icon icon-arrovPrewSm"></i></button>',
        responsive: [{
            breakpoint: 1290,
            settings: {
                dots: false,
                arrows: true,
                autoplaySpeed: 4000,
                speed: 1200,
                slidesToShow: 2,
                slidesToScroll: 1,
                prevArrow: '<button class="icon-roundWrapp icon-parentHover slick-prev" aria-label="Previous" type="button"><i class="icon icon-arrovNextSm"></i></button>',
                nextArrow: '<button class="icon-roundWrapp icon-parentHover slick-next" aria-label="Next" type="button"><i class="icon icon-arrovPrewSm"></i></button>'
            }
        }, {
            breakpoint: 1046,
            settings: {
                dots: false,
                arrows: true,
                autoplaySpeed: 4000,
                speed: 1200,
                slidesToShow: 1,
                slidesToScroll: 1,
                adaptiveHeight: true,
                prevArrow: '<button class="icon-roundWrapp icon-parentHover slick-prev" aria-label="Previous" type="button"><i class="icon icon-arrovNextSm"></i></button>',
                nextArrow: '<button class="icon-roundWrapp icon-parentHover slick-next" aria-label="Next" type="button"><i class="icon icon-arrovPrewSm"></i></button>'
            }
        }]
    });

    $('.callback_slider').slick({
        dots: false,
        arrows: false,
        autoplaySpeed: 2000,
        speed: 1500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true
    });

    $('.js_news_slider').slick({
        dots: false,
        arrows: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        autoplay: false,
        prevArrow: $('.js-news-prev'),
        nextArrow: $('.js-news-next'),
        responsive: [
            {
                breakpoint: 769,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });

    //новости
    let news_state_parameters = {},
        $news_element = $('.js-news-element'),
        $news_preview = $('.js-news-preview'),
        $news_date = $('.js-news-date'),
        $news_title = $('.js-news-title'),
        $news_content = $('.js-news-content');

    $news_element.on('click', function () {
        let $this = $(this),
            this_content = JSON.parse($this.attr('data-content')),
            date = String(this_content.activeFrom).replace(/-/g, "/"),
            timeStart = date.indexOf('T');
        date = new Date(date.substring(0, timeStart));
        const monthsName = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
        let news_month = date.getMonth();

        //console.log(this_content);

        $news_date.html(`${date.getDate()} ${monthsName[news_month]} ${date.getFullYear()}`);
        $news_preview.css('background-image', `url('${this_content.preview.link}')`);
        $news_title.html(this_content.title);
        $news_content.html(this_content.content);

        let news_state_title = this_content.title,
            news_state_url = `?news=${this_content.id}`;

        history.pushState(news_state_parameters, news_state_title, news_state_url);
        history.pathname = news_state_url;
    });

    $news_element.fancybox({
        beforeClose: function (instance, current) {
            history.pushState(news_state_parameters, 'Главная', '/');
        }
    });

    $('.articles_slider').slick({
        dots: false,
        arrows: true,
        autoplaySpeed: 4000,
        speed: 1200,
        slidesToShow: 2,
        slidesToScroll: 1,
        prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"></button>',
        nextArrow: '<button class="slick-next" aria-label="Next" type="button"></button>',
        responsive: [{
            breakpoint: 768,
            settings: {
                dots: false,
                arrows: true,
                autoplaySpeed: 4000,
                speed: 1200,
                slidesToShow: 1,
                slidesToScroll: 1,
                prevArrow: '<button class="slick-prev" aria-label="Previous" type="button"></button>',
                nextArrow: '<button class="slick-next" aria-label="Next" type="button"></button>'
            },
			breakpoint: 414,
			settings: "unslick"
        }]
    });

    $('#articles_slider_btn').on('click', function (e) {
        e.preventDefault();
        $('.articles_slider .slick-next').trigger('click');
    });
    $('.about_us_next_btn').on('click', function (e) {
        e.preventDefault();
        $('.articles_slider .slick-next').trigger('click');
    });

    $('.sly_blok_wr .slides a').click(function (arguments) {
        var card = $(this).attr('href');
        $(card).addClass('active');
        $(card + ' .btn').attr('data-card', card);
        $(card + ' .cardImg').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            asNavFor: $(card).find('.cardImgs'),
            responsive: [{
                breakpoint: 1100,
                settings: {
                    adaptiveHeight: true,
                    slidesToShow: 1,
                    infinite: true,
                    dots: true,
                    slidesToScroll: 1,
                    arrows: true,
                    fade: false,
                    appendDots: $(card).find('.card--number'),
                    appendArrows: $(card).find('.card--arrows'),
                    prevArrow: '<button type="button" class="slide_btn prev_slide"><i class="icon icon-cart-left"></i></button>',
                    nextArrow: '<button type="button" class="slide_btn next_slide"><i class="icon icon-cart-right"></i></button>',
                    customPaging: function (slider, i) {
                        i = i + 1;
                        return '<div class="card--sl">' + i + '</div><div class="card--num">/' + slider.slideCount + '</div>';
                    }
                }
            }]
        });
        $(card + ' .cardImgs').slick({
            vertical: true,
            arrows: true,
            slidesToShow: 4,
            slidesToScroll: 1,
            asNavFor: $(card).find('.cardImg'),
            focusOnSelect: true,
            prevArrow: '<div class="card--prew"><i class="icon icon-upArrow"></i></div>',
            nextArrow: '<div class="card--next"><i class="icon icon-bottomArrow"></i></div>',
            responsive: [{
                breakpoint: 1690,
                settings: {
                    vertical: true,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    asNavFor: $(card).find('.cardImg'),
                    focusOnSelect: true,
                    prevArrow: '<div class="card--prew"><i class="icon icon-upArrow"></i></div>',
                    nextArrow: '<div class="card--next"><i class="icon icon-bottomArrow"></i></div>'
                }
            }, {
                breakpoint: 1501,
                settings: {
                    vertical: true,
                    arrows: true,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    asNavFor: $(card).find('.cardImg'),
                    focusOnSelect: true,
                    prevArrow: '<div class="card--prew"><i class="icon icon-upArrow"></i></div>',
                    nextArrow: '<div class="card--next"><i class="icon icon-bottomArrow"></i></div>'
                }
            }, , {
                breakpoint: 1400,
                settings: {
                    vertical: true,
                    arrows: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    asNavFor: $(card).find('.cardImg'),
                    focusOnSelect: true,
                    prevArrow: '<div class="card--prew"><i class="icon icon-upArrow"></i></div>',
                    nextArrow: '<div class="card--next"><i class="icon icon-bottomArrow"></i></div>'
                }
            }, {
                breakpoint: 1265,
                settings: {
                    vertical: true,
                    arrows: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    asNavFor: $(card).find('.cardImg'),
                    focusOnSelect: true,
                    prevArrow: '<div class="card--prew"><i class="icon icon-upArrow"></i></div>',
                    nextArrow: '<div class="card--next"><i class="icon icon-bottomArrow"></i></div>'
                }
            }, {
                breakpoint: 1100,
                settings: 'unslick'
            }]
        });
    });

	//Section 8
		//Section8 slider for card

    /*$('.section8_cardImg').slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
        arrows: true,
        dots: true,
        fade: true,
        speed: 200,
        asNavFor: $('.section8_cardImgs'),
        appendDots: $('.numbers_promo2'),
        appendArrows: $('.count_promo_slides2'),
        prevArrow: '<button type="button" class="slide_btn2 prev_slide"><i class="icon-hover icon-arrovPrev"></i></button>',
        nextArrow: '<button type="button" class="slide_btn2 next_slide"><i class="icon-hover icon-arrovNext"></i></button>',
        customPaging: function (slider, i) {
             i = i + 1;
             return '<div class="current_slide_num2">' + i + '</div><div class="count_slides2">' + slider.slideCount + '</div>';
         },
        responsive: [{
            breakpoint: 500,
            settings: {
                slidesToShow: 1,
                fade: false,
                variableWidth: true,
                adaptiveHeight: false,
            }
        }],
	});
	$('.section8_cardImgs').slick({
		vertical: true,
		arrows: true,
		infinite: false,
		dots: false,
		slidesToShow: 3,
		slidesToScroll: 1,
		centerMode: true,
		centerPadding: '20px',
		asNavFor: $('.section8_cardImg'),
		focusOnSelect: true,
		lazyLoad: 'ondemand',
		prevArrow: '<div class="card--prew2"><i class="icon icon-upArrow"></i></div>',
		nextArrow: '<div class="card--next2"><i class="icon icon-bottomArrow"></i></div>',
		responsive: [{
            breakpoint: 1690,
            settings: {
                slidesToShow: 4,
            }
        }, {
            breakpoint: 1400,
            settings: {
                slidesToShow: 3,
            }
        }, {
            breakpoint: 1265,
            settings: {
                slidesToShow: 2,
            }
        }, {
            breakpoint: 1100,
            settings: 'unslick'
        }],

	});




	//Slides for text
	var i = 0;
    $('.section8_text').each(function () {
        i++;
        $(this).attr('data-slick-index', i);
    });

    $('.section8_cardImg').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var nextSlideNum = nextSlide + 1;
        $('.section8_text').removeClass('current');
        $('.section8_text[data-slick-index="' + nextSlideNum + '"]').addClass('current');
    });*/

	//End of Section 8

    $('.card--back--dsk').click(function () {
        $('.sly_block_wrapp .card').removeClass('active');
        return false;
    });

    $('.card--back--mob').click(function () {
        $('.sly_block_wrapp .card').removeClass('active');
        $('.section3--block').removeClass('no');
        return false;
    });

    var dataPref, dataCard;
    $('.cardsend').on('click', function () {
        dataPref = $(this).attr('data-pref');
        dataCard = $(this).attr('data-card');
        $('#url').val(dataPref + '=' + dataCard);
        $('#urls').val(dataPref);
    });

    document.addEventListener('wpcf7mailsent', function (event) {
        location = ' https://oksk.msdmos.com/spasibo/' + '?' + dataCard;
    }, false);

    $('.dropdown_wrapp').each(function () {
        if (!$(this).hasClass('active')) {
            $(this).find('.dropdown_box').css({
                'display': 'none'
            });
        }
    });

    $('.dropdown_title_wrapp').on('click', function (e) {
        e.preventDefault();
        var parentBLock = $(this).closest('.dropdown_wrapp');
        var slideBLock = parentBLock.find('.dropdown_box');
        if (slideBLock.is(':hidden')) {
            slideBLock.slideDown(300);
            parentBLock.addClass('active');
        } else {
            slideBLock.slideUp(300);
            parentBLock.removeClass('active');
        }
    });

    $('.dropdown_wrapp .dropdown_box li a').on('click', function (e) {
        e.preventDefault();
        var val = $(this).text();
        var input = $(this).closest('.dropdown_wrapp').find('.inputA');
        var inputHref = $(this).attr('href');
        input.text(val);
        input.attr('href', inputHref);
        $(this).closest('.dropdown_box').find('li a').removeClass('active');
        $(this).addClass('active');
        $('.dropdown_box').slideUp(300);
        $('.dropdown_wrapp').removeClass('active');
        let index = $(this).data('index');
        drawGallery(index);
    });

    $('.goToUrl').click(function () {
        var url = $('.inputA').attr('href');
        window.location = url;
    });

    $('.mob_btn,.mob_btn_dark').click(function () {
        $('.fixmenu').addClass('open');
    });
    $('.mob_btn_close').click(function () {
        $('.fixmenu').removeClass('open');
    });
	$('.steps_thumbnails').slick({
		settings: 'unslick',
		responsive: [
			{
                breakpoint: 3000,
                settings: 'unslick'
            },
			{
                breakpoint: 414,
                settings: {
					arrows: false,
					infinite: false,
					dots: false,
					slidesToShow: 1,
					slidesToScroll: 1,
				}
		}]
	})

    // Переключение табов на описанини
    const aboutTabsSwitch = () => {
        const aboutTabsHeaders = document.querySelectorAll('.js-about-tabs-header');
        const aboutTabsSections = document.querySelectorAll('.js-about-tabs-section');

        aboutTabsHeaders.forEach(item => {
            item.addEventListener('click', (event) => {
                const _this = event.currentTarget;
                const currentSlide = _this.dataset.slide;
                const isActive = _this.classList.contains('active');
                if (!isActive) {
                    aboutTabsSections.forEach(itemSection => {
                        itemSection.classList.remove('active')

                        if (itemSection.dataset.slide === currentSlide) {
                            itemSection.classList.add('active')
                        }
                    })
                    aboutTabsHeaders.forEach(itemHeader => {
                        itemHeader.classList.remove('active');
                    })
                    _this.classList.add('active');
                }
            })
        })
    }
    aboutTabsSwitch();

    // Переключение табов на в ипотекее
    const mortgageTabsSwitch = () => {
        const mortgageTabsHeaders = document.querySelectorAll('.js-mortgage-tabs-header');
        const mortgageTabsSections = document.querySelectorAll('.js-mortgage-tabs-section');

        mortgageTabsHeaders.forEach(item => {
            item.addEventListener('click', (event) => {
                const _this = event.currentTarget;
                const currentSlide = _this.dataset.slide;
                const isActive = _this.classList.contains('active');
                if (!isActive) {
                    mortgageTabsSections.forEach(itemSection => {
                        itemSection.classList.remove('active')

                        if (itemSection.dataset.slide === currentSlide) {
                            itemSection.classList.add('active')
                        }
                    })
                    mortgageTabsHeaders.forEach(itemHeader => {
                        itemHeader.classList.remove('active');
                    })
                    _this.classList.add('active');
                }
            })
        })
    }

    mortgageTabsSwitch();

    function checkSubmitForm($this) {
        let $thisFormLayer = $this.parents('.js-sendform--wrapper'), // оборачиваем все что касается формы
            $thisForm = $thisFormLayer.find('.js-sendform--section'), // начальный блок (с полями, заголовками)
            $thisSuccess = $thisFormLayer.find('.js-sendform--success'), // блок после успешной отправки
            $thisErrorMessage = $thisFormLayer.find('.js-sendform--error_message'), // поле для вывода сообщения если форма не отправилась
            $thisName = $thisFormLayer.find('.js-sendform--clientName').val(),
            $thisMessage = $thisFormLayer.find('.js_form_message').val(),
            $thisMessageBlock = $thisFormLayer.find('.js_form_message'),
            $thisNameInput = $thisFormLayer.find('.js-sendform--clientName'),
            $thisPhoneOne = $thisFormLayer.find('.js_phone_one').val(),
            $thisPhoneTwo = $thisFormLayer.find('.js_phone_two').val(),
            $thisPhoneThree = $thisFormLayer.find('.js_phone_three').val(),
            $thisPhoneFour = $thisFormLayer.find('.js_phone_four').val(),
            $thisPhone = '+7' + '(' + $thisPhoneOne + ')' + $thisPhoneTwo  + '-' +  $thisPhoneThree  + '-' +  $thisPhoneFour,
            $thisNameBlock = $thisFormLayer.find('.js-sendform--clientName'),
            $thisPhoneBlock = $thisFormLayer.find('.js_tel_wrap'),
            $thisParentModal = $('#requestСall');

        function submitForm() {
            if (!window.smartCaptcha) {
                return;
            }

            window.smartCaptcha.execute();
            if (!$thisMessageBlock.length) {
                $.ajax({
                    type: 'POST',
                    url: "/send_order",
                    headers: {"Content-type": "application/x-www-form-urlencoded"},
                    data: {firstname: $thisName, tel: $thisPhone, token: scripts.yandexCaptcha.forms['captcha-container']},
                    beforeSend: function () {
                        $this.attr('disabled', 'disabled');
                        console.log($thisParentModal);
                        $thisParentModal.addClass('request-call--thanks');
                        $thisForm.hide();
                        $thisSuccess.show();
                    },
                    success: function () {
                        $thisForm.hide();
                        $thisSuccess.show();
                    },
                    error: function () {
                        $this.removeAttr('disabled');
                        $thisErrorMessage.text('Ошибка отправки. Попробуйте снова');
                    }
                });
            } else {
                $.ajax({
                    type: 'POST',
                    url: "/send_order",
                    headers: {"Content-type": "application/x-www-form-urlencoded"},
                    data: {message: $thisMessage, tel: $thisPhone, token: scripts.yandexCaptcha.forms['captcha-container-fm']},
                    beforeSend: function () {
                        $this.attr('disabled', 'disabled');
                        $thisForm.hide();
                        $thisSuccess.show();
                    },
                    success: function () {
                        $thisForm.hide();
                        $thisSuccess.show();
                    },
                    error: function () {
                        $this.removeAttr('disabled');
                        $thisErrorMessage.text('Ошибка отправки. Попробуйте снова');
                    }
                });
            }
        }

        if (($thisNameInput.length)) {
            if (($thisName === '') && ($thisPhoneOne === '' || $thisPhoneTwo === '' || $thisPhoneThree === '' || $thisPhoneFour === '')) {
                $thisNameBlock.addClass('input-text--error');
                $thisPhoneBlock.addClass('input-text--error');
            } else if ($thisName === '') {
                $thisPhoneBlock.removeClass('input-text--error');
                $thisNameBlock.addClass('input-text--error');
            } else if ($thisPhoneOne === '' || $thisPhoneTwo === '' || $thisPhoneThree === '' || $thisPhoneFour === '') {
                $thisNameBlock.removeClass('input-text--error');
                $thisPhoneBlock.addClass('input-text--error');
            } else {
                submitForm();
            }
        } else if (!$thisNameInput.length) {
            if ($thisPhoneOne === '' || $thisPhoneTwo === '' || $thisPhoneThree === '' || $thisPhoneFour === '') {
                $thisPhoneBlock.addClass('input-text--error');
            } else {
                submitForm();
            }
        }

        return false;
    }

    $('.js-sendform--submit').click(function (event) {
        event.preventDefault();
        checkSubmitForm($(event.currentTarget));
    });

    $('.js_modal_close').click(function (event) {
        event.preventDefault();
        $('.mfp-close').trigger('click');
    });

    function smoothScrollToSection() {
        const smoothButtons = Array.from(document.querySelectorAll('.smooth'));

        function smoothScroll(button) {
            const section = document.getElementById(`${getElementId(button)}`);
            return section.scrollIntoView({inline: "center", behavior: "smooth"});
        }

        function getElementId(element) {
            return element.parentElement.dataset.menuanchor;
        }

        function closeMenu() {
            const menu = document.querySelector('.fixmenu');

            if (menu.classList.contains('open')) {
                return menu.classList.remove('open');
            }
        }

        return smoothButtons.map(button => button.addEventListener('click', function(event) {
            event.preventDefault();
            closeMenu();
            smoothScroll(button);
        }));
    }

    smoothScrollToSection();

    let $filter_price_ranger = $("#flats_price_slider");
    let $filter_area_ranger = $("#flats_area_slider");


    let $double_range = $('.js-filter-group-range');

    $double_range.each(function () {
        let $thisInputs = $(this).find('.input-text');

        $(this).rangerInputs('init');

        $thisInputs.each(function () {
            let inputs_formated = new Cleave($(this), {
                delimiter: ' ',
                numeral: true,
                numeralThousandsGroupStyle: 'thousand'
            });
        });
    });

    //камеры
    let $cameras_change_link = $('.js-change-camera'),
        $this_cameras_frame = $('.js-camera-frame');

    $cameras_change_link.on('click', function () {
        let $this = $(this),
            this_data = $this.data('src'),
            this_active = $this.hasClass('active');

        if (!this_active) {
            $cameras_change_link.removeClass('active');
            $this.addClass('active');
            if ($this_cameras_frame.length) {
                $this_cameras_frame.attr('src', this_data);
            }
        }
    });
});
